import React, { useState, useEffect } from "react";
import Select from "react-select";
import {
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
} from "@mui/material";
import Generate from "./Generate";
import "../../Pricing.css";
import { Auth } from "aws-amplify";



const Pricing = () => {
  const [cropName, setCropName] = useState("Soyabean");
  const [cropVariety, setCropVariety] = useState("Yellow"); // Default variety for Soyabean
  const [marketName, setMarketName] = useState("Indore");
  const [priceSource, setPriceSource] = useState("Agmarknet");
  const [priceUnit, setPriceUnit] = useState(null);
  const [strikeMethod, setstrikeMethod] = useState(null);
  const [StrikeStartDate, setStrikeStartDate] = useState("");
  const [error, setError] = useState("");
  const [StrikeEndDate, setStrikeEndDate] = useState("");
  const [monitoringStartDate, setMonitoringStartDate] = useState("");
  const [monitoringEndDate, setMonitoringEndDate] = useState("");
  const [strikePrice, setStrikePrice] = useState("");
  const [strikePricePercentile, setStrikePricePercentile] = useState("");
  const [exitPrice, setExitPrice] = useState("");
  const [deTrendData, setDeTrendData] = useState("no");
  const [showGenerate, setShowGenerate] = useState(false); // State to control Generate component visibility
  const [generateclicked, setgenerateclicked] = useState(1); // State to control Generate component visibility
  const [endDateError1, setEndDateError1] = useState(false);
  const [endDateError2, setEndDateError2] = useState(false);
  const [historicalPrices, setHistoricalPrices] = useState([]); // New state for historical price data
  const [editedHistoricalPrices, setEditedHistoricalPrices] = useState([]); // State to store edited prices
  const [currentStrikePrice, setcurrentStrikePrice] = useState("");
  const [insuranceType, setInsuranceType] = useState(null);
  const [movingAvgWindow, setmovingAvgWindow] = useState(null);
  const [postHarvestSelected, setpostHarvestSelected] = useState(false);
  const [bucketWindow] = useState(1);
  const [typeofRisk, settypeofRisk] = useState(null);
  const [cropOptions, setCropOptions] = useState([]);
  const [varietyOptions, setVarietyOptions] = useState([]);
  const [marketOptions, setMarketOptions] = useState([]);
  const [sourceOptions, setSourceOptions] = useState([]);
  const [outlierPercentage, setOutlierPercentage] = useState(null)

  const insurance_type_options = [
    { value: "Sowing", label: "Sowing" },
    { value: "Post-Harvest", label: "Post-Harvest" },
  ];


  const handleStartDateChange = (e) => {
    const selectedStartDate = e.target.value;
    setMonitoringStartDate(selectedStartDate);
    if (monitoringEndDate) {
      if (new Date(selectedStartDate) < new Date(monitoringEndDate))
        setEndDateError1(false);
      else
        setEndDateError1(true);
    }
  };


  const handleEndDateChange = (e) => {
    const selectedEndDate = e.target.value;
    setMonitoringEndDate(selectedEndDate);

    if (monitoringStartDate) {
      if (new Date(monitoringStartDate) < new Date(selectedEndDate))
        setEndDateError1(false);
      else
        setEndDateError1(true);
    }
  };



  const handleStrikeStartDateChange = (e) => {
    const selectedStartDate = e.target.value;
    setStrikeStartDate(selectedStartDate);

    if (StrikeEndDate) {
      if (new Date(selectedStartDate) < new Date(StrikeEndDate))
        setEndDateError2(false);
      else
        setEndDateError2(true);
    }
  };


  const handleStrikeEndDateChange = (e) => {
    const selectedEndDate = e.target.value;
    setStrikeEndDate(selectedEndDate);

    if (StrikeStartDate) {
      if (new Date(StrikeStartDate) < new Date(selectedEndDate))
        setEndDateError2(false);
      else
        setEndDateError2(true);
    }
  };

  const isFormValid =
    cropName &&
    marketName &&
    StrikeStartDate &&
    StrikeEndDate &&
    priceSource &&
    monitoringStartDate &&
    monitoringEndDate &&
    exitPrice &&
    priceUnit;

  const handleClear = () => {
    setCropName(null);
    setCropVariety(null);
    setMarketName(null);
    setPriceSource(null);
    setMonitoringStartDate("");
    setMonitoringEndDate("");
    setStrikePrice("");
    setStrikePricePercentile("");
    setStrikeStartDate("");
    setStrikeEndDate("");
    setExitPrice("");
    setDeTrendData("no");
    setShowGenerate(false);
    setgenerateclicked(1);
    setHistoricalPrices([]);
    setEditedHistoricalPrices([]);
    setcurrentStrikePrice("");
    setInsuranceType("");
    setmovingAvgWindow(null);
  };

  const handleGenerateStrikePrice = async () => {
    const { accessToken: { jwtToken } } = await Auth.currentSession();

    if (isFormValid) {
      try {


        // Prepare the request body, conditionally including `percentile`
        const requestBody = {
          start_date: (StrikeStartDate),
          end_date: (StrikeEndDate),
          source: priceSource,
          commodity: cropName,
          market_name: marketName,
          strike_price_type: strikeMethod,
          crop_variety: cropVariety,
          ...(strikeMethod === "Percentile" && {
            percentile: parseInt(strikePricePercentile, 10),
          }),
        };

        // Use fetch to call the API
        const response = await fetch("/api/pricing/historicalStrikePrice", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": jwtToken,
          },
          body: JSON.stringify(requestBody),
        });

        // Parse the response
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const resultData = await response.json();
        setStrikePrice(resultData.strike_price);
        setHistoricalPrices(resultData); // Set the data from the API response
        if (resultData.length !== 0) {
          setEditedHistoricalPrices(resultData);
        } else {
          setEditedHistoricalPrices([]);
        }
      } catch (error) {
        setHistoricalPrices([]);
        console.error("Error fetching strike price:", error);
        setEditedHistoricalPrices([]);
      }
    }
  };


  const handleGenerate = async () => {
    if (isFormValid) {
      try {
        if (editedHistoricalPrices.length === 0)
          handleGenerateStrikePrice()
        // Set states after successful API call
        setShowGenerate(true);
        setgenerateclicked((prev) => prev + 1);
      } catch (error) {
        console.error("Error fetching strike price:", error);
      }
    }
  };

  useEffect(() => {
    // Logic to generate historical strike price table
    handleGenerateStrikePrice()
    // eslint-disable-next-line
  }, [
    StrikeStartDate,
    StrikeEndDate,
    priceSource,
    cropName,
    marketName,
    strikeMethod,
    strikePricePercentile,
    insuranceType,
    cropVariety
  ]);

  const handlePriceChange = (index, newValue) => {
    setEditedHistoricalPrices((prevPrices) => {
      const updatedPrices = [...prevPrices];
      updatedPrices[index].price = newValue;
      return updatedPrices;
    });
  };

  const handleWeightChange = (index, newValue) => {
    setEditedHistoricalPrices((prevPrices) => {
      const updatedPrices = [...prevPrices];
      updatedPrices[index].weight = newValue;
      return updatedPrices;
    });
  };

  // Fetch Crop Names
  useEffect(() => {
    const fetchCropNames = async () => {
      try {
        const { accessToken: { jwtToken } } = await Auth.currentSession();
        const response = await fetch("/api/pricing/getCropName", {
          headers: {
            Authorization: jwtToken,
          },
        });
        const data = await response.json();
        const formattedOptions = data.crops.map((crop) => ({
          label: crop,
          value: crop,
        }));
        setCropOptions(formattedOptions);
      } catch (error) {
        console.error("Error fetching crop names:", error);
      }
    };
    fetchCropNames();
  }, []);

  useEffect(() => {
    if (cropName) {
      const fetchMarketNames = async () => {
        try {
          const { accessToken: { jwtToken } } = await Auth.currentSession();
          const response = await fetch(
            `/api/pricing/getMarketName?crop_name=${encodeURIComponent(
              cropName
            )}`,
            {
              headers: {
                Authorization: jwtToken,
              },
            }
          );
          const data = await response.json();
          const formattedOptions = data.map((market) => ({
            label: market._id,
            value: market._id,
          }));
          setMarketOptions(formattedOptions);
        } catch (error) {
          console.error("Error fetching market names:", error);
        }
      };
      fetchMarketNames();
    } else {
      setMarketOptions([]);
    }
  }, [cropName]);


  useEffect(() => {
    console.log("triggered 1")
    if (marketName) {
      const fetchCropVarieties = async () => {
        try {
          const { accessToken: { jwtToken } } = await Auth.currentSession();
          const response = await fetch(
            `/api/pricing/getCropVariety?crop_name=${encodeURIComponent(cropName)}&market_name=${encodeURIComponent(marketName)}`,
            {
              headers: {
                Authorization: jwtToken,
              },
            }
          );
          console.log("triggered 2")
          const data = await response.json();
          const formattedOptions = data.map((variety) => ({
            label: variety.variety,
            value: variety.variety,
          }));
          console.log(formattedOptions)
          setVarietyOptions(formattedOptions);
        } catch (error) {
          console.error("Error fetching crop varieties:", error);
        }
      };
      fetchCropVarieties();
    } else {
      setVarietyOptions([]);
    }
    // eslint-disable-next-line
  }, [marketName]);


  // Fetch Price Sources
  useEffect(() => {
    if (cropName && cropVariety && marketName) {
      const fetchPriceSources = async () => {
        try {
          const { accessToken: { jwtToken } } = await Auth.currentSession();
          const response = await fetch(
            `/api/pricing/getSourceName?crop_name=${encodeURIComponent(
              cropName
            )}&crop_variety=${encodeURIComponent(
              cropVariety
            )}&market_name=${encodeURIComponent(marketName)}`,
            {
              headers: {
                Authorization: jwtToken,
              },
            }
          );
          const data = await response.json();
          const formattedOptions = data.map((source) => ({
            label: source._id,
            value: source._id,
          }));
          setSourceOptions(formattedOptions);
        } catch (error) {
          console.error("Error fetching price sources:", error);
        }
      };
      fetchPriceSources();
    } else {
      setSourceOptions([]);
    }
  }, [cropName, cropVariety, marketName]);


  return (
    <div className="compare-pannel">
      <div>
        <div className="exploretext">Premium Calculation</div>
        <div className="compareecondarytext">
          Fill the required input fields to calculate the pure premium
        </div>
        <div className="download-container">
          <h2 className="select-label">
            Crop Name
          </h2>
          <Select
            className="custom-select"
            placeholder="Crop Name"
            value={cropName ? { label: cropName, value: cropName } : null}
            onChange={(selectedOption) => {
              setCropName(selectedOption.value);
              setCropVariety(null);
              setMarketName(null);
              setPriceSource(null);
            }}
            options={cropOptions}
          />
        </div>

        <div className="download-container">
          <h2 className="select-label">
            Market Name
          </h2>
          <Select
            className="custom-select"
            placeholder="Market Name"
            value={marketName ? { label: marketName, value: marketName } : null}
            onChange={(selectedOption) => {
              setMarketName(selectedOption.value)
              setCropVariety(null);
              setPriceSource(null);
            }}
            options={marketOptions}
            isDisabled={!cropName}
          />
        </div>

        <div className="download-container">
          <h2 className="select-label">
            Crop Variety
          </h2>
          <Select
            className="custom-select"
            placeholder="Crop Variety"
            value={
              cropVariety ? { label: cropVariety, value: cropVariety } : null
            }
            onChange={(selectedOption) => {
              setCropVariety(selectedOption.value);
              setPriceSource(null);
            }}
            options={varietyOptions}
            isDisabled={!cropName || !marketName}
          />
        </div>

        <div className="download-container">
          <h2 className="select-label">
            Price Source
          </h2>
          <Select
            className="custom-select"
            placeholder="Price Source"
            value={
              priceSource ? { label: priceSource, value: priceSource } : null
            }
            onChange={(selectedOption) => setPriceSource(selectedOption.value)}
            options={sourceOptions}
            isDisabled={!cropName || !marketName}
          />
        </div>

        <div className="download-container">
          <h2 className="select-label">
            Type of Insurance <span className="mandatory"></span>
          </h2>
          <Select
            className="custom-select"
            options={insurance_type_options}
            placeholder="Type of Insurance"
            onChange={(obj) => {
              setInsuranceType(obj.value);
              setEditedHistoricalPrices([]);
              setpostHarvestSelected(obj.value === "Post-Harvest"); // Update based on selection
            }}
          />
        </div>
        {insuranceType === "Post-Harvest" && (
          <div className="download-container">
            <div className="date-item">
              <h2 className="select-label">
                Moving Average Window (Days(1-30)){" "}
              </h2>
              <TextField
                id="strikePricepercentile"
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="number"
                error={!!error}
                helperText={error}
                onChange={(e) => {
                  const value = parseInt(e.target.value, 10);
                  if (!Number.isInteger(value) || value < 1 || value > 30) {
                    setError("Please enter an integer between 1 and 30.");
                    setmovingAvgWindow(""); // Clear the state if input is invalid
                  } else {
                    setError(""); // Clear the error if input is valid
                    setmovingAvgWindow(value);
                  }
                }}
                sx={{
                  margin: "4px 0",
                  ".MuiInputBase-root": { height: "45px", fontSize: "14px" },
                }}
              />
            </div>
            {/* <div className="date-item">
              <h2 className="select-label">
                Bucket Window
              </h2>
              <TextField
                id="strikePricepercentile"
                fullWidth
                type="number"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setbucketWindow(e.target.value)}
                sx={{
                  margin: "4px 0",
                  ".MuiInputBase-root": { height: "45px", fontSize: "14px" },
                }}
              />
            </div> */}
          </div>
        )}
        <div className="download-container">
          <h2 className="select-label">
            Type of Risk
          </h2>
          <Select
            className="custom-select"
            placeholder="Type of risk"
            value={typeofRisk ? { label: typeofRisk, value: typeofRisk } : null}
            onChange={(selectedOption) => settypeofRisk(selectedOption.value)}
            options={[
              { value: "Upside", label: "Upside" },
              { value: "Downside", label: "Downside" },
            ]}
          />
        </div>
        {/* Monitoring Duration */}
        <div className="download-container">
          <h2 className="select-label">
            Monitoring Duration
          </h2>
          <div className="date-row">
            <div className="date-item">
              <h2 className="date-label">Start Date</h2>
              <TextField
                id="monitoringStartDate"
                type="date"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={monitoringStartDate}
                onChange={handleStartDateChange}
                sx={{
                  margin: "4px 0",
                  ".MuiInputBase-root": { height: "45px", fontSize: "14px" },
                }}
              />
            </div>
            <div className="date-item">
              <h2 className="date-label">End Date</h2>
              <TextField
                id="monitoringEndDate"
                type="date"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={monitoringEndDate}
                onChange={handleEndDateChange}
                error={endDateError1} // Shows red border if there's an error
                helperText={
                  endDateError1
                    ? "End Date cannot be earlier than Start Date"
                    : ""
                }
                sx={{
                  margin: "4px 0",
                  ".MuiInputBase-root": {
                    height: "45px",
                    fontSize: "14px",
                    borderColor: endDateError1 ? "red" : "inherit",
                  },
                }}
              />
            </div>


          </div>
        </div>
        <div className="download-container">
          <h2 className="select-label">
            Historical Strike Calculation
          </h2>

          <div className="date-row">
            <div className="date-item">
              <h2 className="date-label">Start Date</h2>
              <TextField
                id="StrikeStartDate"
                type="date"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={StrikeStartDate}
                onChange={handleStrikeStartDateChange}
                sx={{
                  margin: "4px 0",
                  ".MuiInputBase-root": { height: "45px", fontSize: "14px" },
                }}
              />
            </div>
            <div className="date-item">
              <h2 className="date-label">End Date</h2>
              <TextField
                id="StrikeEndDate"
                type="date"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={StrikeEndDate}
                onChange={handleStrikeEndDateChange}
                error={endDateError2}
                helperText={
                  endDateError2
                    ? "End Date cannot be earlier than Start Date"
                    : ""
                }
                sx={{
                  margin: "4px 0",
                  ".MuiInputBase-root": {
                    height: "45px",
                    fontSize: "14px",
                    borderColor: endDateError2 ? "red" : "inherit",
                  },
                }}
              />
            </div>
          </div>
        </div>
        <div className="download-container">
          <h2 className="select-label">
            Price Unit
          </h2>
          <Select
            className="custom-select"
            placeholder="Price unit"
            value={priceUnit ? { label: priceUnit, value: priceUnit } : null}
            onChange={(selectedOption) => setPriceUnit(selectedOption.value)}
            options={[
              { value: "Rs/Quintal", label: "Rs/Quintal" },
              { value: "Rs/kg", label: "Rs/kg" },
            ]}
          />
        </div>
        <div className="download-container">
          <div className="date-row">
            <div className="date-item">
              <h2 className="select-label">
                Current Strike Price
              </h2>
              <TextField
                id="StrikePrice"
                type="number"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={currentStrikePrice}
                onChange={(e) => setcurrentStrikePrice(e.target.value)}
                sx={{
                  margin: "4px 0",
                  ".MuiInputBase-root": { height: "45px", fontSize: "14px" },
                }}
              />
            </div>
            <div className="date-item">
              <h2 className="select-label">
                Current Exit Price
              </h2>
              <TextField
                id="exitPrice"
                type="number"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={exitPrice}
                onChange={(e) => setExitPrice(e.target.value)}
                sx={{
                  margin: "4px 0",
                  ".MuiInputBase-root": { height: "45px", fontSize: "14px" },
                }}
              />
            </div>
          </div>
        </div>

        <div className="download-container">
          <h2 className="select-label">
            Historical Strike Method
          </h2>
          <Select
            className="custom-select"
            placeholder="Strike Method"
            value={
              strikeMethod ? { label: strikeMethod, value: strikeMethod } : null
            }
            onChange={(selectedOption) => setstrikeMethod(selectedOption.value)}
            options={[
              { value: "Average", label: "Average" },
              { value: "Percentile", label: "Percentile" },
            ]}
          />
        </div>

        {strikeMethod === "Percentile" && (
          <div className="download-container">
            <div className="date-item">
              <h2 className="select-label">
                Strike Percentile
              </h2>
              <TextField
                id="strikePricepercentile"
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setStrikePricePercentile(e.target.value)}
                sx={{
                  margin: "4px 0",
                  ".MuiInputBase-root": { height: "45px", fontSize: "14px" },
                }}
              />
            </div>
          </div>
        )}

        <div className="download-container" style={{ marginBottom: "80px" }}>
          <div className="date-row">
            <div className="date-item">
              <h2 className="select-label">Remove outlier</h2>
              <RadioGroup
                row
                value={deTrendData}
                onChange={(event) => { setDeTrendData(event.target.value) }}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </div>
          </div>
          {deTrendData === "Yes" ? (<div className="date-item">
            <h2 className="select-label">
              Outlier Percentage
            </h2>
            <TextField
              id="outlierPercentage"
              type="number"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={outlierPercentage}
              onChange={(e) => setOutlierPercentage(e.target.value)}
              sx={{
                margin: "4px 0",
                ".MuiInputBase-root": { height: "45px", fontSize: "14px" },
              }}
            />
          </div>
          ) : ('')}
        </div>
        {historicalPrices.length > 0 && (
          <div className="download-container" style={{ marginBottom: "180px" }}>
            <h2 className="select-label">Historical Strike Prices</h2>
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
              <thead>
                <tr>
                  <th style={{ border: "1px solid #ccc", padding: "8px" }}>
                    Year
                  </th>
                  <th style={{ border: "1px solid #ccc", padding: "8px" }}>
                    Price
                  </th>
                  <th style={{ border: "1px solid #ccc", padding: "8px" }}>
                    Weight
                  </th>
                </tr>
              </thead>
              <tbody>
                {editedHistoricalPrices.map((item, index) => (
                  <tr key={index}>
                    <td style={{ border: "1px solid #ccc", padding: "8px" }}>
                      {item.year}
                    </td>
                    <td style={{ border: "1px solid #ccc", padding: "8px" }}>
                      <input
                        type="number"
                        value={item.price}
                        onChange={(e) =>
                          handlePriceChange(index, parseFloat(e.target.value))
                        }
                        style={{ width: "100%" }}
                      />
                    </td>
                    <td style={{ border: "1px solid #ccc", padding: "8px" }}>
                      <input
                        type="number"
                        value={item.weight}
                        onChange={(e) =>
                          handleWeightChange(index, parseFloat(e.target.value))
                        }
                        style={{ width: "100%" }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        <div
          className="button-container"
          style={{
            position: "fixed",
            bottom: "0",
            width: "380px",
            backgroundColor: "white",
            zIndex: "1000",
            padding: "15px 20px",
            display: "flex",
            justifyContent: "flex-end",
            borderTop: "1px solid #ccc",
          }}
        >
          <Button
            variant="contained"
            style={{
              backgroundColor: "#F1F5F9",
              color: "#000",
              marginRight: "20px",
            }}
            onClick={handleClear}
          >
            Clear
          </Button>

          <Button
            variant="contained"
            style={{
              backgroundColor: isFormValid ? "#0880EA" : "#ccc",
              color: "#fff",
            }}
            //disabled={!isFormValid}
            onClick={handleGenerate}
          >
            Generate Output
          </Button>
        </div>
      </div>

      {showGenerate && (
        <div
          className="generate-panel"
          style={{
            position: "fixed",
            right: "0",
            top: "0",
            width: "calc(100vw - 470px)",
            height: "100vh",
            backgroundColor: "#fff",
            zIndex: "1000",
            padding: "24px",
            borderLeft: "1px solid rgba(26, 26, 0, 0.16)",
            overflow: "auto",
            marginTop: "40px",
          }}
        >
          <Generate
            showGenerate={showGenerate}
            cropName={cropName}
            cropVariety={cropVariety}
            monitoringStartDate={monitoringStartDate}
            monitoringEndDate={monitoringEndDate}
            strikePrice={strikePrice}
            exitPrice={exitPrice}
            currentStrikePrice={currentStrikePrice}
            priceSource={priceSource}
            priceUnit={priceUnit}
            marketName={marketName}
            generateclicked={generateclicked}
            editedHistoricalPrices={editedHistoricalPrices}
            postHarvestSelected={postHarvestSelected}
            insuranceType={insuranceType}
            movingAvgWindow={movingAvgWindow}
            bucketWindow={bucketWindow}
            typeofRisk={typeofRisk}
            outlier_perecentage={outlierPercentage}
            outlier_status={deTrendData}
          />
        </div>
      )}
    </div>
  );
};

export default Pricing;
