import React from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { Button } from '@mui/material';
import { IoDownloadOutline } from "react-icons/io5";

const PriceTrend = (props) => {
  // Log props for debugging
  console.log("pricetrend compo", props.data);

  const formatData = (seriesData) => {
    return seriesData.map(item => [item.date * 1000, item.price]);
  };

  const downloadCSV = () => {
    // Prepare CSV content
    let csvContent = "data:text/csv;charset=utf-8,Date,Price\n";
    const source = Object.keys(props.data)[0]; // Get the first available source
    props.data[source].forEach(item => {
      const date = new Date(item[0]).toLocaleDateString();
      csvContent += `${date},${item[1]}\n`;
    });

    // Create a link to download
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${source}_price_data.csv`);
    document.body.appendChild(link); // Required for Firefox
    link.click();
    document.body.removeChild(link);
  };

  let series = [];
  let data = [];
  let options = {};
  const getColorForSource = (source) => {
    switch (source) {
      case "Agmarknet":
        return '#18794E';
      case "e-NAM":
        return '#0B68CB';
      case "NCDEX":
        return '#544FC5';
      default:
        return '#ccc'; // Default color for unknown source
    }
  };

  // Loop through each data source in props.data
  Object.keys(props.data).forEach(source => {
    if (props.data[source].length > 0) {
      data = formatData(props.data[source]);
      series.push({
        name: source,
        data: data,
        marker: { enabled: false },
        lineWidth: 1,
        color: getColorForSource(source), // Add function to determine color
        tooltip: { valueSuffix: ' per Quintal' }
      });
    }
  });

  

  options = {
    title: { text: '' },
    xAxis: {
      type: 'datetime',
      title: { text: 'Date' }
    },
    yAxis: {
      title: { text: 'Price' }
    },
    series: series,
    tooltip: {
      shared: true,
      crosshairs: true
    },
    legend: {
      enabled: true,
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'top',
      floating: false,
      y: 10,
    },
    credits: { enabled: false } 
  };

  return (
    <div style={{ position: 'relative', paddingBottom: '50px' }}>
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={'stockChart'}
        options={options}
      />
      <div style={{
        position: 'absolute',
        bottom: '10px',
        right: '10px',
      }}>
        <Button 
         variant="outlined"
         startIcon={<IoDownloadOutline />}
          onClick={downloadCSV} 
          style={{
            borderRadius: "8px",
            border: "1px solid #451E11",
            color: "black",
          }}
        >
          Download Graph Data
        </Button>
      </div>
    </div>
  );
};

export default PriceTrend;