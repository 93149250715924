import React, { useState, useEffect } from "react";
import { Button, Card } from "reactstrap";
import { PriceComparison } from "./PriceComparison";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { BiDownload } from "react-icons/bi";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import CollapsiblePriceTable from "./CollapsiblePriceTable";

export const Monitor = (props) => {
  const [value, setValue] = useState("1");
  const [comparisonData, setComparisonData] = useState(null);
  const [policiesdetails, setPoliciesDetails] = useState([]);
  //const [endorseeData, setEndorseeData] = useState([]);
  const navigate = useNavigate();

  /*const handleDownload = async (aggregatorName) => {
    const {
      accessToken: { jwtToken },
    } = await Auth.currentSession();
    try {
      const response = await fetch("/api/monitor/downloadEndorseeData", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
        body: JSON.stringify({
          policy_number: props.selectedPolicyNo,
          aggregator_name: aggregatorName,
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }

      const blob = await response.blob(); // Get the response as a Blob
      const url = window.URL.createObjectURL(blob); // Create a URL for the Blob
      const a = document.createElement("a"); // Create an anchor element
      a.href = url;
      a.download = "endorsee_table.zip"; // Set the download file name
      document.body.appendChild(a);
      a.click();
      a.remove(); // Clean up the DOM
    } catch (error) {
      console.error("There was an error downloading the file:", error);
    }
  };
  */

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

 /* // Fetch endorsee data
  useEffect(() => {
    async function fetchData() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
      fetch("/api/monitor/getEndorseeData", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
        body: JSON.stringify({
          policy_number: props.selectedPolicyNo,
          master_holder: props.selectedPolicymasterHolder,
        }),
      })
        .then((response) => response.json())
        .then((data) => setEndorseeData(data));
    }
    fetchData();
  }, [props.selectedPolicyNo, props.selectedPolicymasterHolder]);
  */

  // Fetch price comparison data
  useEffect(() => {
    async function fetchData() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
      fetch(
        "/api/monitor/priceComparisonGraph?policy_number=" +
          props.selectedPolicyNo,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => setComparisonData(data));
    }
    fetchData();
  }, [props.selectedPolicyNo]);

  useEffect(() => {
    async function fetchData() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();

      fetch(
        "/api/monitor/getPolicyDetails?policy_number=" + props.selectedPolicyNo,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => setPoliciesDetails(data));
    }
    fetchData();
  }, [props.selectedPolicyNo]);

  return (
    <>
      <div
        className="row justify-content-center "
        style={{
          overflowY: "scroll",
          background: "#F2F2F0",
        }}
      >
        <div className="col-md-11">
          <div
            className="row justify-content-center mt-2"
            style={{ marginLeft: "40px" }}
          >
            <div className="col-md-12">
              <div className="row">
                <div
                  className="col-md-1 label"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <img
                    src="chevron-left.png"
                    alt="location icon"
                    style={{ marginRight: 10 }}
                    onClick={() => {
                      props.setMenu("Monitor");
                      navigate("/monitorPolicies");
                    }}
                  />
                  <span
                    style={{
                      color: "#0B68CB",
                      fontSize: "16px",
                      fontWeight: 400,
                      lineHeight: "16px",
                      letterSpacing: "0.04px",
                      textAlign: "left",
                    }}
                  >
                    {policiesdetails ? policiesdetails.insurance_company : null}
                  </span>
                </div>
              </div>
              <div className="row mt-3 label">
                <div className="col-md-1 label">{props.selectedPolicyNo}</div>
                <div className="col-md-2">
                  <span
                    className="p-1"
                    style={{
                      borderColor: "#AED6F1",
                      backgroundColor: "#E1F0FF",
                      color: "#0B68CB",
                    }}
                  >
                    {policiesdetails ? policiesdetails.policy_start_date : null}{" "}
                    -{policiesdetails ? policiesdetails.policy_end_date : null}
                  </span>
                </div>
              </div>
              <Card color="primary" outline className="mt-2">
  <div className="title ms-3">Policy Details</div>
  <div className="container">
    <div className="row align-items-center d-flex flex-wrap">
      <div className="col-md-2 policy-label">
        Crop Name
        <br />
        <span className="title">
          {policiesdetails ? policiesdetails.crop : null}
        </span>
      </div>
      <div className="col-md-2 policy-label">
        Market Name
        <br />
        <span className="title">
          {policiesdetails ? policiesdetails.market : null}
        </span>
      </div>
      <div className="col-md-1 policy-label">
        Strike Price
        <br />
        <span className="title">
          {policiesdetails ? policiesdetails.strike_price : null}
        </span>
      </div>
      <div className="col-md-1 policy-label">
        Exit Price
        <br />
        <span className="title">
          {policiesdetails ? policiesdetails.exit_price : null}
        </span>
      </div>
      <div className="col-md-1 policy-label">
        Actual Price
        <br />
        <span className="title">
          {policiesdetails ? policiesdetails.actual_amount : null}
        </span>
      </div>
      <div className="col-md-2 policy-label">
        Total Sum Insured
        <br />
        <span className="title">
          {policiesdetails ? policiesdetails.sum_insured : null}
        </span>
      </div>
      {policiesdetails?.insurance_type?.includes("Sowing") && (
        <div className="col-md-2 policy-label">
          Trigger status
          <br />
          <span className="title">
            {policiesdetails?.trigger ? "True" : "False"}
          </span>
        </div>
      )}
      <div className="col-md-1 policy-label">
        Policy Status
        <br />
        <span className="title">
          {policiesdetails ? policiesdetails.status : null}
        </span>
      </div>
    </div>
  </div>
</Card>

            </div>
          </div>
          <div
            className="row justify-content-center mt-3"
            style={{
              backgroundColor: "#fff",
              width: "100vw",
              paddingLeft: "30px",
              paddingRight: "30px",
            }}
          >
            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                  >
                    <Tab label="Comparison" value="1" />
                    <Tab label="Price" value="2" />
                    {/* <Tab label="Endorsee Listed" value="3" /> */}
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <div className="row justify-content-between">
                    <div className="col-md-2 title">
                      Price Dataset Comparison
                    </div>
                    <div className="col-md-3">
                      <Button outline>
                        <BiDownload size={20} className="me-2" />
                        Download Data
                      </Button>
                    </div>
                  </div>
                  {comparisonData ? (
                    <PriceComparison data={comparisonData} />
                  ) : null}
                </TabPanel>
                <TabPanel value="2">
                  <div className="row ">
                    <div className="col-md-12 d-flex justify-content-end mb-3">
                      <Button outline>
                        <BiDownload size={20} className="me-2" />
                        Download Data
                      </Button>
                    </div>
                    <CollapsiblePriceTable
                      policy_number={props.selectedPolicyNo}
                      insurancetype={policiesdetails.insurance_type}
                    />
                  </div>
                </TabPanel>

                {/* <TabPanel value="3">
                  <TableContainer component={Paper} style={{ height: 300 }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Aggregator Name</TableCell>
                          <TableCell align="right">Endorsee Count</TableCell>
                          <TableCell align="right">Sum Insured</TableCell>
                          <TableCell align="right">Status</TableCell>
                          <TableCell align="right"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {endorseeData.map((row) => (
                          <TableRow
                            key={row["_id"]}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {row.aggregator}
                            </TableCell>
                            <TableCell align="right">
                              {row.endorsee_count}
                            </TableCell>
                            <TableCell align="right">
                              {row.sum_insured}
                            </TableCell>
                            <TableCell align="right">{row.status}</TableCell>
                            <TableCell align="right">
                              <Button
                                color="link"
                                onClick={
                                  () => handleDownload(row.aggregator) // Call handleDownload with required parameters
                                }
                              >
                                Download Endorsee Table
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </TabPanel> */}
              </TabContext>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
};
