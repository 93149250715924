import React, { useState, useEffect } from "react";
import Select from "react-select";
import {Button} from "@mui/material";
import "../../Pricing.css";
import { LuRefreshCcw } from "react-icons/lu";
import CompareExplore from "./CompareExplore";
import { Auth } from "aws-amplify";

const currentYear = new Date().getFullYear();
const startYear = 2014;
const years = Array.from({ length: currentYear - startYear + 1 }, (_, i) => startYear + i).map((year) => ({
  value: year,
  label: year,
}));

const months = [
  { value: "January", label: "January" },
  { value: "February", label: "February" },
  { value: "March", label: "March" },
  { value: "April", label: "April" },
  { value: "May", label: "May" },
  { value: "June", label: "June" },
  { value: "July", label: "July" },
  { value: "August", label: "August" },
  { value: "September", label: "September" },
  { value: "October", label: "October" },
  { value: "November", label: "November" },
  { value: "December", label: "December" },
];

const Explore = (props) => {
  const [cropName, setCropName] = useState("Soyabean");
  const [cropVariety, setCropVariety] = useState("Yellow"); // Default variety for Soyabean
  const [marketName, setMarketName] = useState("Indore");
  // const [priceSource, setPriceSource] = useState("Agmarknet");
  const [policyStartYear, setPolicyStartYear] = useState(2014);
  const [policyEndYear, setPolicyEndYear] = useState(2024);
  const [monitoringStartMonth, setMonitoringStartMonth] = useState("January");
  const [monitoringEndMonth, setMonitoringEndMonth] = useState("December");
  const [deTrendData, setDeTrendData] = useState("no");
  const [showCompare, setShowCompare] = useState(false); // State to control Generate component visibility
  const [compareclicked, setcompareclicked] = useState(1); // State to control Generate component visibility
  const [priceTrendData, setPriceTrendData] = useState(null);
  const [monthlyAveragePricesData, setMonthlyAveragePricesData] = useState([]);
  const [monthlyAvgVsArrivalData, setMonthlyAvgVsArrivalData] = useState(null);
  const [heatMapData, setHeatMapData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [cropOptions, setCropOptions] = useState([]);
  const [varietyOptions, setVarietyOptions] = useState([]);
  const [marketOptions, setMarketOptions] = useState([]);
 const [priceSource, setPriceSource] = useState(null);
  const isFormValid =
    cropName &&
    marketName &&
    policyStartYear &&
    policyEndYear &&
    monitoringStartMonth &&
    monitoringEndMonth &&
    deTrendData;

  const handleResetbuttonlick = () => {
    setCropName(null);
    setMarketName(null);
    setPolicyStartYear(null);
    setPolicyEndYear(null);
    setMonitoringStartMonth(null);
    setMonitoringEndMonth(null);

    setDeTrendData("no");
    setcompareclicked(1)
    setShowCompare(false); // Hide Generate component on clear
  };

  const handleGenerate = () => {
    if (isFormValid) {
      setShowCompare(true); 
      setcompareclicked(prev => prev + 1);// Show Generate component
      fetchData();
    }
  };
  const fetchMonthlyAverageVsArrival = async () => {
    setLoading(true);
    const { accessToken: { jwtToken } } = await Auth.currentSession();
    try {
      const response = await fetch(
        `/api/explore/monthlyAverageVsArrival?commodity=${cropName}&market_name=${marketName}&start_year=${policyStartYear}&end_year=${policyEndYear}&start_month=${monitoringStartMonth}&end_month=${monitoringEndMonth}&crop_variety=${cropVariety}&source=${priceSource}`, 
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": jwtToken,
          },
        }
      );
      const data = await response.json();
      setMonthlyAvgVsArrivalData(data);
    } catch (error) {
      console.error("Error fetching Monthly Average Vs Arrival data:", error);
      setMonthlyAvgVsArrivalData([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (priceSource) {
      fetchMonthlyAverageVsArrival();
    }
    // eslint-disable-next-line
  }, [priceSource]);

  
  const fetchData = async () => 
    {
    
       const { accessToken: { jwtToken } } = await Auth.currentSession();
       try {
       const priceTrendResponse = await fetch(
         `/api/explore/priceGraph?commodity=${cropName}&market_name=${marketName}&start_year=${policyStartYear}&end_year=${policyEndYear}&start_month=${monitoringStartMonth}&end_month=${monitoringEndMonth}&crop_variety=${cropVariety}`, {
           method: "GET",
           headers: {
             "Content-Type": "application/json",
             "Authorization": jwtToken,
           },
         }
       );
       const data = await priceTrendResponse.json();
       console.log('explore',priceTrendData)
       setPriceTrendData(data);
      }
      catch (error) {
        console.error("Error fetching data:", error);
        setPriceTrendData([])
      }
      try{
       const monthlyAvgVsArrivalResponse = await fetch(
         `/api/explore/monthlyAverageVsArrival?commodity=${cropName}&market_name=${marketName}&start_year=${policyStartYear}&end_year=${policyEndYear}&start_month=${monitoringStartMonth}&end_month=${monitoringEndMonth}&crop_variety=${cropVariety}&source=${priceSource}`, {
           method: "GET",
           headers: {
             "Content-Type": "application/json",
             "Authorization": jwtToken,
           },
         }
       );
       const data = await monthlyAvgVsArrivalResponse.json();
       setMonthlyAvgVsArrivalData(data);
      }
      catch (error) {
        console.error("Error fetching data:", error);
        setMonthlyAvgVsArrivalData([])
      }
      try{
       const heatMapResponse = await  fetch(
         `/api/explore/heatMap?commodity=${cropName}&market_name=${marketName}&start_year=${policyStartYear}&end_year=${policyEndYear}&start_month=${monitoringStartMonth}&end_month=${monitoringEndMonth}&crop_variety=${cropVariety}&source=${priceSource}`, {
           method: "GET",
           headers: {
             "Content-Type": "application/json",
             "Authorization": jwtToken,
           },
         }
       );
       setHeatMapData(await heatMapResponse.json());
      }
      catch (error) {
        console.error("Error fetching data:", error);
        setHeatMapData([]);
      }
      try{
       const monthlyAveragePricesResponse = await fetch(
         `/api/explore/monthlyAveragePrices?commodity=${cropName}&market_name=${marketName}&start_year=${policyStartYear}&end_year=${policyEndYear}&start_month=${monitoringStartMonth}&end_month=${monitoringEndMonth}&crop_variety=${cropVariety}&source=${priceSource}`, {
           method: "GET",
           headers: {
             "Content-Type": "application/json",
             "Authorization": jwtToken,
           },
         }
       );
       setMonthlyAveragePricesData(await monthlyAveragePricesResponse.json());
     } catch (error) {
       console.error("Error fetching data:", error);
       setMonthlyAveragePricesData([])
     }
      finally {
       setLoading(false);
     }
   };


   useEffect(() => {
    const fetchCropNames = async () => {
      try {
        const { accessToken: { jwtToken } } = await Auth.currentSession();
        const response = await fetch("/api/explore/getCropName", {
          headers: {
            Authorization: jwtToken,
          },
        });
        const data = await response.json();
        const formattedOptions = data.crops.map((crop) => ({
          label: crop,
          value: crop,
        }));
        setCropOptions(formattedOptions);
        setMarketName(null)
        setCropVariety(null)
        setPriceSource(null)
      } catch (error) {
        console.error("Error fetching crop names:", error);
      }
    };
  
    fetchCropNames();
  }, []);

   useEffect(() => {
    if (cropName) {
      const fetchMarketNames = async () => {
        try {
          const { accessToken: { jwtToken } } = await Auth.currentSession();
          const response = await fetch(
            `/api/explore/getMarketName?crop_name=${encodeURIComponent(
              cropName
            )}`,
            {
              headers: {
                Authorization: jwtToken,
              },
            }
          );
          const data = await response.json();
          const formattedOptions = data.map((market) => ({
            label: market._id,
            value: market._id,
          }));
          setMarketOptions(formattedOptions);
          setVarietyOptions([])
          setCropVariety(null)
        } catch (error) {
          console.error("Error fetching market names:", error);
        }
      };
      fetchMarketNames();
    }
   
  }, [cropName]);
  
  
  // Fetch crop varieties when cropName changes
  useEffect(() => {
    if (cropName && marketName) {
      const fetchCropVarieties = async () => {
        try {
          const { accessToken: { jwtToken } } = await Auth.currentSession();
          const response = await fetch(
            `/api/explore/getCropVariety?crop_name=${encodeURIComponent(
              cropName
            )}&market_name=${encodeURIComponent(marketName)}`,
            {
              headers: {
                Authorization: jwtToken,
              },
            }
          );
          const data = await response.json();
          const formattedOptions = data.map((variety) => ({
            label: variety.variety,
            value: variety.variety,
          }));
          setVarietyOptions(formattedOptions);
        } catch (error) {
          console.error("Error fetching crop varieties:", error);
        }
      };
  
      fetchCropVarieties();
    } else {
      setVarietyOptions([]); // Clear variety options when no crop is selected
    }
  }, [cropName,marketName]);
  
 
  
  return (
    <div className="compare-pannel">
      <div>
        <div className="exploretext">Explore</div>
        <div className="compareecondarytext">
          Make required selections to view available datasets.
        </div>

        <div className="download-container">
        <h2 className="select-label">Crop Name</h2>
        <Select
          className="custom-select"
          placeholder="Crop"
          value={cropName ? { label: cropName, value: cropName } : null}
          onChange={(selectedOption) => {
            setCropName(selectedOption.value);
            setCropVariety(null); // Reset variety when crop changes
          }}
          options={cropOptions}
        />
      </div>

      <div className="download-container">
        <h2 className="select-label">Market Name</h2>
        <Select
          className="custom-select"
          placeholder="Market"
          value={marketName ? { label: marketName, value: marketName } : null}
          onChange={(selectedOption) => setMarketName(selectedOption.value)}
          options={marketOptions}
          isDisabled={!cropName}
        />
      </div>

      <div className="download-container">
        <h2 className="select-label">
          Crop Variety
        </h2>
        <Select
          className="custom-select"
          placeholder="Crop Variety"
          value={cropVariety ? { label: cropVariety, value: cropVariety } : null}
          onChange={(selectedOption) => {
            setCropVariety(selectedOption.value);
          }}
          options={varietyOptions}
          isDisabled={!marketName} // Disable if no crop is selected
        />
      </div>



        {/* Policy Duration */}
        <div className="download-container">
          <h2 className="select-label">Select Start and End Year</h2>
          <div className="date-row">
            <div className="date-item">
              <Select
                className="custom-select"
                placeholder="Start Year"
                value={policyStartYear? { label: policyStartYear, value: policyStartYear } : null}
                onChange={(selectedOption) =>
                  setPolicyStartYear(selectedOption.value)
                }
                options={years}
              />
            </div>
            <div className="date-item">
              <Select
                className="custom-select"
                placeholder="End Year"
                value={policyEndYear? { label: policyEndYear, value: policyEndYear } : null}
                onChange={(selectedOption) => setPolicyEndYear(selectedOption.value)}
                options={years}
              />
            </div>
          </div>
        </div>

        {/* Monitoring Duration */}
        <div className="download-container">
          <h2 className="select-label">
            Select Start and End Month 
          </h2>
          <div className="date-row">
            <div className="date-item">
              <Select
                className="custom-select"
                placeholder="Start Month"
                value={monitoringStartMonth? { label: monitoringStartMonth, value: monitoringStartMonth } : null}
                onChange={(selectedOption) =>
                  setMonitoringStartMonth(selectedOption.value)
                }
                options={months}
              />
            </div>
            <div className="date-item">
              <Select
                className="custom-select"
                placeholder="End Month"
                value={monitoringEndMonth? { label: monitoringEndMonth, value: monitoringEndMonth } : null}
                onChange={(selectedOption) =>
                  setMonitoringEndMonth(selectedOption.value)
                }
                options={months}
              />
            </div>
          </div>
        </div>

        <div className="compare-button-container">
        <Button
            variant="contained"
            style={{
              backgroundColor: isFormValid ? "#0880EA" : "#ccc",
              width:'300px',
              color: "#fff",
            }}
            onClick={handleGenerate}
            disabled={!isFormValid}
          >
            Compare
          </Button>
          <div className="resetbutton" onClick={handleResetbuttonlick}>
            <LuRefreshCcw style={{ marginRight: "10px" }} />
            Reset selections
          </div>
        </div>
      </div>

      {
        <div
          className="generate-panel"
          style={{
            position: "fixed",
            right: "0",
            top: "0",
            width: "calc(100vw - 470px)",
            height: "100vh",
            backgroundColor: "#fff",
            zIndex: "1000",
            padding: "24px",
            borderLeft: "1px solid rgba(26, 26, 0, 0.16)",
            overflow: "auto",
            marginTop: "40px",
          }}
        >
          <CompareExplore
           compareclicked={compareclicked}
           marketName={marketName}
           cropName={cropName}
           policyStartYear={policyStartYear}
           policyEndYear={policyEndYear}
           monitoringStartMonth={monitoringStartMonth}
           monitoringEndMonth={monitoringEndMonth}
           showCompare={showCompare} 
           cropVariety={cropVariety}
           priceTrendData={priceTrendData}
           monthlyAveragePricesData={monthlyAveragePricesData}
           heatMapData={heatMapData}
           monthlyAvgVsArrivalData={monthlyAvgVsArrivalData}
           loading={loading}
           priceSource={priceSource}
           setPriceSource={setPriceSource}
           />
        </div>
      }
    </div>
  );
};

export default Explore;
