import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Auth } from "aws-amplify";


const CollapsiblePriceTable = ({ policy_number, insurancetype }) => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState({});
  const [movingAverageKey, setMovingAverageKey] = useState('');
  const [priceColumns, setPriceColumns] = useState([]);

  useEffect(() => {
     async function fetchData(){
        const { accessToken: { jwtToken } } = await Auth.currentSession();
    fetch(`/api/monitor/monitoringTable?policy_number=${policy_number}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: jwtToken,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        console.log(result)
        setData(result);
        if (result.length > 0 && result[0].days.length > 0) {
          const dayKeys = Object.keys(result[0].days[0]);
          const priceKeys = dayKeys.filter(key => key !== 'date' && key !== 'avg' && key !== 'Claim Status' && !key.includes('Moving Average'));
          setPriceColumns(priceKeys);
          const dynamicKey = dayKeys.find(key => key.includes('Moving Average'));
          if (dynamicKey) setMovingAverageKey(dynamicKey);
        }
      })
      .catch((error) => console.error('Error fetching data:', error));
    }
    fetchData();
  }, [policy_number]);

  const handleToggle = (month) => {
    setOpen((prevOpen) => ({
      ...prevOpen,
      [month]: !prevOpen[month],
    }));
  };

  const getClaimStatusStyles = (status) => {
    switch (status) {
      case 'Freeze period':
        return { backgroundColor: '#B0BEC5', borderRadius: '8px', padding: '4px 5px' };
      case 'Trigger':
        return { backgroundColor: '#FF5252', borderRadius: '8px', padding: '4px 5px', color: 'white' };
      case 'No trigger':
        return { backgroundColor: '#1976D2', borderRadius: '8px', padding: '4px 5px', color: 'white' };
      default:
        return {};
    }
  };

  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow style={{ backgroundColor: "#F2F2F0" }}>
              <TableCell align="center">Month</TableCell>
              <TableCell align="center">Day</TableCell>
              {priceColumns.map((col) => (
                <TableCell key={col} align="center">{col}</TableCell>
              ))}
              <TableCell align="center">Average Price</TableCell>
              {!insurancetype.includes('Sowing') && <TableCell align="center">{movingAverageKey}</TableCell>}
              {!insurancetype.includes('Sowing') && <TableCell align="center">Claim Status</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((monthData, index) => (
              <React.Fragment key={index}>
                <TableRow>
                  <TableCell colSpan={insurancetype.includes('Sowing') ? 3 + priceColumns.length : 4 + priceColumns.length}>
                    <IconButton onClick={() => handleToggle(monthData.month)}>
                      {open[monthData.month] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                    {monthData.month}
                  </TableCell>
                </TableRow>
                {open[monthData.month] && (monthData.days.length === 0 || !monthData.days) ? (
                  <TableRow>
                    <TableCell colSpan={insurancetype.includes('Sowing') ? 3 + priceColumns.length : 4 + priceColumns.length} align="center">
                      No data available for {monthData.month}
                    </TableCell>
                  </TableRow>
                ) : (
                  open[monthData.month] && monthData.days.map((day, idx) => (
                    <TableRow key={idx}>
                      {idx === 0 && (
                        <TableCell rowSpan={monthData.days.length} align="center">
                          {monthData.month}
                        </TableCell>
                      )}
                      <TableCell align="center">{day.date}</TableCell>
                      {priceColumns.map((col) => (
                        <TableCell key={col} align="center">{day[col] ?? 'N/A'}</TableCell>
                      ))}
                      <TableCell align="center">{day.avg ? day.avg.toFixed(2) : 'N/A'}</TableCell>
                      {!insurancetype.includes('Sowing') && <TableCell align="center">{day[movingAverageKey] ?? 'N/A'}</TableCell>}
                      {!insurancetype.includes('Sowing') && (
                        <TableCell align="center">
                          <span style={getClaimStatusStyles(day['Claim Status'])}>
                            {day['Claim Status'] ? day['Claim Status'] : 'N/A'}
                          </span>
                        </TableCell>
                      )}
                    </TableRow>
                  ))
                )}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default CollapsiblePriceTable;