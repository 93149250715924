import React, { useState } from "react";
import "../styles.css";
import { IoIosLogOut } from "react-icons/io";
import { FaCircleUser, FaChevronUp } from "react-icons/fa6";
import { IoSettingsOutline } from "react-icons/io5";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { FaChevronDown } from "react-icons/fa6";
import { Auth } from "aws-amplify";

const Header = (props) => {
  const [isProfileOpen, setProfileOpen] = useState(false);

  const headerStyle = {
    height: "48px",
    padding: "6px 16px",
    borderBottom: "1px solid #E0E0E0",
    background: "#FEFCFB",
    position: "fixed",
    top: "0",
    left: "0",
    right: "0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    zIndex: "1001",
  };

  const iconsContainerStyle = {
    display: "flex",
    gap: "24px",
    alignItems: "center",
  };

  const handleProfileClick = () => {
    setProfileOpen(!isProfileOpen);
  };

  const handleLogout = async() => {
      try {
        await Auth.signOut();
        window.location.reload();
      } catch (error) {
        console.log("error signing out: ", error);
      }
  };

  const profilePopupStyle = {
    position: "fixed",
    top: "48px",
    right: "10px",
    width: "180px", // Reduced width
    height: "124px",
    background: "#fff",
    padding: "14px", // Adjusted padding to fit smaller width
    border: "1px solid rgba(26, 26, 0, 0.16)",
    zIndex: 10002,
    display: isProfileOpen ? "flex" : "none",
    borderRadius: "8px",
    boxShadow: "0px 4px 6px 0px rgba(28, 28, 2, 0.08)",
    flexDirection: "column",
  };

  return (
    <div>
      <div style={headerStyle}>
        <div style={iconsContainerStyle}>
          <div
            className="label"
            style={{
              color: "#1c2024",
              left: 0,
              letterSpacing: 0,
              position: "relative",
            }}
          >
            Price Insurance Calculator
          </div>
        </div>
        <div style={{ display: "flex", gap: "10px", alignItems: "center", cursor: "pointer" }} onClick={handleProfileClick}>
          <FaCircleUser size={21} />
          <span style={{ fontSize: "14px", fontWeight: "bold" }}>User</span>
          {isProfileOpen ? <FaChevronUp /> : <FaChevronDown />}
        </div>
      </div>
      {isProfileOpen ? (
        <div style={profilePopupStyle}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
            }}
          >
           
          </div>
          <div style={{ alignItems: "center" }}>
            
            {/* Menu Item: Settings */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                cursor: "pointer",
                margin: "6px 0",
              }}
              // onClick={handleSettings}
            >
              <IoSettingsOutline color="#000" />
              <div
                style={{
                  fontSize: "14px", // Increased font size
                  marginLeft: "5px",
                }}
              >
                Settings
              </div>
            </div>
            {/* Menu Item: View Profile */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                cursor: "pointer",
                margin: "6px 0",
              }}
              // onClick={handleViewProfile}
            >
              <MdOutlineRemoveRedEye color="#000" />
              <div
                style={{
                  fontSize: "14px", // Increased font size
                  marginLeft: "5px",
                }}
              >
                View Profile
              </div>
            </div>
            {/* Menu Item: Logout */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                cursor: "pointer",
                margin: "6px 0",
              }}
              onClick={handleLogout}
            >
              <IoIosLogOut />
              <div
                style={{
                  fontSize: "14px", // Increased font size
                  marginLeft: "5px",
                }}
              >
                Logout
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Header;
