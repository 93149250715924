import React from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

export const PriceComparison = ({ data }) => {
  // Helper function to convert data format for Highcharts
  const transformData = (inputData) => {
    return inputData.map((point) => [point.date * 1000, point.price]);
  };

  // Define colors for different market types
  const marketColors = {
    "Agmarknet": "#18794E",
    "e-NAM": "#0B68CB",
    "NCDEX": "#544FC5",
    "Strike Price": "#FF0000",
  };

  // Process dynamic market data and maintain "Strike Price" separately
  const seriesData = Object.keys(data).map((key) => {
    return {
      name: key,
      data: data[key] ? transformData(data[key]) : [],
      color: marketColors[key.includes("Agmarknet") ? "Agmarknet" : key.includes("e-NAM") ? "e-NAM" : key] || "#000000",
      dashStyle: key === "Strike Price" ? "Dash" : "Solid",
    };
  });

  const options = {
    title: {
      text: "",
      align: "left",
    },

    yAxis: {
      title: {
        text: "Rs/Quintal",
      },
    },

    xAxis: {
      type: "datetime",
      accessibility: {
        rangeDescription: "Range: 2020 to 2022",
      },
    },

    legend: {
      layout: "vertical",
      align: "center",
      verticalAlign: "top",
    },

    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
      },
    },

    series: seriesData,

    credits: {
      enabled: false,
    },

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom",
            },
          },
        },
      ],
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};
