import React, { useState, useEffect } from "react";
import { Tabs, Tab, Box, Typography, Button } from "@mui/material";
import SummaryGraph from "./SummaryGraph";
import { IoDownloadOutline } from "react-icons/io5";
import axios from "axios";
import PostHarvestGraph from "./PostHarvestGraph";
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import { Auth } from "aws-amplify";

const Generate = (props) => {
  const [tabValue, setTabValue] = useState(0);
  const [premium, setPremium] = useState(null);
  const [summaryGraphData, setSummaryGraphData] = useState(null);
  const [maxPayoutActive, setMaxPayoutActive] = useState(true);
  const [percentilePayoutActive, setPercentilePayoutActive] = useState(true);
  const [avgPayoutActive, setAvgPayoutActive] = useState(true);
  const boxes = premium
    ? [
      {
        label: "Max Premium",
        value: premium.max_premium !== null && premium.max_premium !== undefined
          ? parseFloat(premium.max_premium.toFixed(2))
          : null,
        isActive: maxPayoutActive,
        tooltip: `Yearly Avg of Max payouts across ${props.bucketWindow}-Day buckets`,
      },
      {
        label: "Average Premium",
        value: premium.avg_premium !== null && premium.avg_premium !== undefined
          ? parseFloat(premium.avg_premium.toFixed(2))
          : null,
        isActive: avgPayoutActive,
        tooltip: `Yearly Avg of Avg payouts across ${props.bucketWindow}-Day buckets`,
      },
      {
        label: "Percentile Premium",
        value: premium["95th_percentile_premium"] !== null && premium["95th_percentile_premium"] !== undefined
          ? parseFloat(premium["95th_percentile_premium"].toFixed(2))
          : null,
        isActive: percentilePayoutActive,
        tooltip: `Yearly Avg of Percentile payouts across ${props.bucketWindow}-Day buckets`,
      },
    ]
    : [];


  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleDownload = async () => {
    const { accessToken: { jwtToken } } = await Auth.currentSession();

    try {
    
      const body = {
        source: props.priceSource,
        commodity: props.cropName,
        market_name: props.marketName,
        insurance_type: props.insuranceType,
        crop_variety: props.cropVariety,
        monitoring_start_date: (props.monitoringStartDate),
        monitoring_end_date: (props.monitoringEndDate),
        current_exit_price: parseInt(props.exitPrice, 10),
        current_strike_price: parseInt(props.currentStrikePrice, 10),
        strike_price: props.editedHistoricalPrices,
        risk_type:props.typeofRisk,
        outlier_status: props.outlier_status,
        outlier_percentage: parseInt(props.outlier_percentage, 10)
      }
      if (props.insuranceType === "Post-Harvest") {
        body.moving_average_window_days = props.movingAvgWindow;
        body.bucket_size = parseInt(props.bucketWindow, 10);

      }

      try {
        const response = await axios.post(
          "/api/pricing/downloadPriceData",
          body,
          {
            responseType: "blob", // Ensures the response is treated as a binary file
            headers: {
              "Content-Type": "application/json",
              'Authorization':jwtToken
            },
          }
        );

        // Create a URL for the Excel file
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "pricing_data.xlsx"); // Updated file extension to .xlsx

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Cleaning up after download
      } catch (error) {
        console.error("Error downloading file:", error);
        alert("Failed to download file");
      };
    }
    catch (error) {
      console.error("Error downloading file:", error);
      alert("Failed to download file");
    };
  }

  useEffect(() => {
    const fetchPremium = async () => {
      const { accessToken: { jwtToken } } = await Auth.currentSession();
  
      try {
  
        const body = {
          monitoring_start_date: (props.monitoringStartDate),
          monitoring_end_date: (props.monitoringEndDate),
          source: props.priceSource,
          strike_price: props.editedHistoricalPrices,
          commodity: props.cropName,
          unit: props.priceUnit,
          current_exit_price: parseInt(props.exitPrice, 10),
          current_strike_price: parseInt(props.currentStrikePrice, 10),
          insurance_type: props.insuranceType,
          crop_variety: props.cropVariety,
          risk_type: props.typeofRisk,
          outlier_status: props.outlier_status,
          outlier_percentage: parseInt(props.outlier_percentage, 10)
        };
  
        if (props.insuranceType === "Post-Harvest") {
          body.moving_average_window_days = props.movingAvgWindow;
          body.bucket_size = parseInt(props.bucketWindow, 10);
        }

        const response = await fetch("/api/pricing/premium", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": jwtToken,
          },
          body: JSON.stringify(body),
        });
  
        const data = await response.json();
        setPremium(data); // Assuming 'premium' is the key in the response
      } catch (error) {
        console.error("Error fetching premium data:", error);
        setPremium(null);
      }
    };
  
    const fetchPayout = async () => {
      const { accessToken: { jwtToken } } = await Auth.currentSession();
  
      try {
  
        const body = {
          monitoring_start_date: (props.monitoringStartDate),
          monitoring_end_date: (props.monitoringEndDate),
          source: props.priceSource,
          strike_price: props.editedHistoricalPrices,
          commodity: props.cropName,
          unit: props.priceUnit,
          current_exit_price: parseInt(props.exitPrice, 10),
          current_strike_price: parseInt(props.currentStrikePrice, 10),
          insurance_type: props.insuranceType,
          crop_variety: props.cropVariety,
          risk_type: props.typeofRisk,
          outlier_status: props.outlier_status,
          outlier_percentage: parseInt(props.outlier_percentage, 10)
        };
  
        if (props.insuranceType === "Post-Harvest") {
          body.moving_average_window_days = props.movingAvgWindow;
          body.bucket_size = parseInt(props.bucketWindow, 10);
        }
  
        const response = await fetch("/api/pricing/payout", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": jwtToken,
          },
          body: JSON.stringify(body),
        });
  
        const data = await response.json();
        setSummaryGraphData(data);
      } catch (error) {
        console.error("Error fetching payout data:", error);
        setSummaryGraphData(null);
      }
    };
  
    fetchPremium();
    fetchPayout();
    // eslint-disable-next-line
  }, [props.generateclicked]);
  
  return (
    <Box sx={{ width: "100%" }}>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="Generate Tabs"
      >
        <Tab
          label="Summary"
          sx={{ textTransform: "capitalize", fontSize: "16px" }}
        />
        <Tab
          label="Risk Classification"
          disabled
          sx={{ textTransform: "capitalize", fontSize: "16px" }}
        />
      </Tabs>
      <Box sx={{ p: 3, position: "relative" }}>
        {tabValue === 0 && (
          <>
            {props.postHarvestSelected ? (
              <Box display="flex" gap={2}>
                {boxes.map(
                  ({ label, value, isActive, tooltip }, index) =>
                    isActive && (
                      <Box
                        key={index}
                        sx={{
                          width: "200px",
                          height: "76px",
                          padding: "16px",
                          gap: "3px",
                          borderRadius: "12px",
                          border: "2px solid #0880EA",
                          opacity: 1,
                          backgroundColor: "#F5FAFF",
                          display: "flex",
                          alignContent: "flex-start",
                        }}
                      >
                        <Box
                          sx={{
                            flex: 1,
                            paddingLeft: "5px",
                            flexDirection: "row",
                            textAlign: "left",
                          }}
                        >
                          <Typography
                            variant="body2"
                            sx={{ fontSize: "12px", fontWeight: 400, color: "#101D46", display: "flex", alignItems: "center" }}
                          >
                            {label}
                            <Tooltip title={tooltip} arrow>
                              <InfoIcon sx={{ fontSize: "16px", color: "#101D46", marginLeft: "5px", cursor: "pointer" }} />
                            </Tooltip>
                          </Typography>
                          <Typography variant="h6" sx={{ color: "#0880EA" }}>
                            {value !== null ? value + "%" : "Loading..."}
                          </Typography>
                        </Box>
                      </Box>
                    )
                )}
              </Box>
            )
              :
              <Box
                sx={{
                  width: "200px",
                  height: "76px",
                  padding: "16px",
                  gap: "3px",
                  borderRadius: "12px",
                  border: "2px solid #0880EA",
                  opacity: 1,
                  backgroundColor: "#F5FAFF",
                  display: "flex",
                  alignContent: "flex-start",
                }}
              >

                <Box sx={{
                  flex: 1, paddingLeft: "5px", flexDirection: "row",
                  textAlign: "left",
                }}>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "12px", fontWeight: 400, color: "#101D46" }}
                  >
                    Pure Premium Rate
                  </Typography>
                  <Typography variant="h6" sx={{ color: "#0880EA" }}>
                    {premium && premium.premium !== null ? premium.premium + "%" : "Loading..."}
                  </Typography>
                </Box>
              </Box>
            }
            {/* Include the SummaryGraph component here */}
            <Box sx={{ mt: 3 }}>
              {summaryGraphData !==null ? (
                
                <>
                 <div
                 style={{
                   fontSize: "16px",
                   fontWeight: 500,
                   textAlign: "left",
                   marginBottom: "20px",
                 }}
               >
                 Historical Payout(%) of Max Payout (or Sum Insured)
               </div>
                  {props.postHarvestSelected ? (
                      <PostHarvestGraph
                        data={summaryGraphData}
                        maxPayoutActive={maxPayoutActive}
                        setMaxPayoutActive={setMaxPayoutActive}
                        avgPayoutActive={avgPayoutActive}
                        setAvgPayoutActive={setAvgPayoutActive}
                        percentilePayoutActive={percentilePayoutActive}
                        setPercentilePayoutActive={setPercentilePayoutActive}
                      />
                  ) : (
                      <SummaryGraph data={summaryGraphData} />
                  )}
                </>
              ) : 
                (<Typography>No data available for selected input selection</Typography>
              )}
            </Box>

            <Button
              variant="outlined"
              startIcon={<IoDownloadOutline />}
              // onClick={downloadPdfDocument}
              style={{
                position: "absolute",
                right: "16px",
                height: "35.527px",
                padding: "8px 16px",
                alignItems: "center",
                gap: "8px",
                flexShrink: 0,
                borderRadius: "8px",
                border: "1px solid #451E11",
                marginTop: "35px",
                color: "black",
              }}
              onClick={handleDownload}
            >
              Download data
            </Button>
          </>
        )}
        {tabValue === 1 && <Typography>Risk Classification Content</Typography>}
      </Box>
    </Box>
  );
};

export default Generate;
